<template>
  <div class="container">
    <img
      class="top-bgi"
      v-if="!isPhoneWidth"
      src="https://media.litelyapp.com/web/top_bgi.webp"
    />
    <!-- <div class="top-bgi1"></div> -->
    <div class="home-top">
      <div class="home-top-descript">
        <div class="descript-title">Fast Together, Lose Weight Easier</div>
        <div class="descript-tip">
          The first community-based fasting app for weight loss and healthy
          lifestyle
        </div>
        <div class="descript-list">
          <div class="descript-item" v-for="(item, ind) in tipList" :key="ind">
            <img src="@/assets/img/checked.png" /> {{ item }}
          </div>
        </div>
        <div class="descript-download">
          <div class="google-download">
            <a :href="googleDownloadLink" target="_blank"
              ><img
                src="https://media.litelyapp.com/web/google_download.webp"
                alt="googlePay"
            /></a>
          </div>
          <div class="ios-download">
            <a :href="appStoreDownloadLink" target="_blank"
              ><img
                src="https://media.litelyapp.com/web/ios_download.webp"
                alt="ios"
            /></a>
          </div>
        </div>
      </div>
      <div class="home-top-banner">
        <img
          v-if="isPhoneWidth"
          src="@/assets/img/fixed_img.svg"
          class="fixed-img"
        />
        <img src="https://media.litelyapp.com/web/ios.webp" />
        <!-- https://media.litelyapp.com/web/fixed_img.webp -->
      </div>
    </div>
    <div class="home-describe">
      <div class="home-describe-txt">
        Studies have shown that Intermittent Fasting (IF) is safe and effective.
        Intermittent Fasting (IF) was covered on:
      </div>
      <div v-if="!isPhoneWidth" class="home-describe-imgs">
        <img src="https://media.litelyapp.com/web/img1.webp" />
        <img src="https://media.litelyapp.com/web/img2.webp" />
        <img src="https://media.litelyapp.com/web/img3.webp" />
        <img src="https://media.litelyapp.com/web/img4.webp" />
      </div>
      <div v-else class="home-describe-rows">
        <div class="home-describe-rows-imgs">
          <img src="https://media.litelyapp.com/web/img1.webp" />
          <img src="https://media.litelyapp.com/web/img2.webp" />
        </div>
        <div class="home-describe-rows-imgs">
          <img src="https://media.litelyapp.com/web/img3.webp" />
          <img src="https://media.litelyapp.com/web/img4.webp" />
        </div>
      </div>
    </div>
    <div class="home-who">
      <div class="home-who-title">Why Choose Litely?</div>
      <div class="home-who-describe">
        Created by fasting enthusiasts, Litely provides you with innovative and
        unique features that help you reach your fasting goals in an easy way.
      </div>
      <div class="home-who-list" v-if="!isPhoneWidth">
        <div class="home-who-item" v-for="(item, ind) in whoList" :key="ind">
          <img :src="item.url" />
          <div class="home-who-item-title">{{ item.title }}</div>
          <div class="home-who-item-txt">{{ item.txt }}</div>
        </div>
      </div>
      <div class="home-who-model1 home-who-model" v-else>
        <img :src="whoList[0].url" />
        <div class="home-who-model-title">{{ whoList[0].title }}</div>
        <div class="home-who-model-txt">{{ whoList[0].txt }}</div>
      </div>
    </div>
    <div class="home-who-model-list" v-if="isPhoneWidth">
      <div
        class="home-who-model"
        :class="'home-who-model' + (ind + 1)"
        v-if="ind > 0"
        v-for="(item, ind) in whoList"
        :key="ind"
      >
        <img :src="item.url" />
        <div class="home-who-model-title">{{ item.title }}</div>
        <div class="home-who-model-txt">{{ item.txt }}</div>
      </div>
    </div>
    <div class="home-fast" v-if="!isPhoneWidth">
      <div class="home-fast-title">Why Fasting?</div>
      <div class="home-fast-describe">
        Intermittent fasting (IF) is one of the fastest and easiest ways to lose
        weight. Studies have shown that it has many health benefits for our
        bodies.
      </div>
      <div class="home-fast-content">
        <div class="home-fast-content-banner">
          <img v-lazy="doctorOmg" />
        </div>
        <div class="home-fast-content-list">
          <div
            class="home-fast-content-item"
            v-for="(item, ind) in fastList"
            :key="ind"
          >
            <div class="fast-item-title">
              <img v-lazy="item.url" />
              <span>{{ item.title }}</span>
            </div>
            <div class="fast-item-txt">
              {{ item.txt }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-fast-moble" v-else>
      <div class="home-fast-moble-top">
        <div class="home-fast-moble-top-title">Why Fasting?</div>
        <div class="home-fast-moble-top-describe">
          Intermittent fasting (IF) is one of the fastest and easiest ways to
          lose weight. Studies have shown that it has many health benefits for
          our bodies.
        </div>
        <div class="home-fast-moble-top-banner">
          <img v-lazy="doctorOmg" />
          <!-- <img src="@/assets/img/doctor.webp"> -->
        </div>
      </div>
      <div class="home-fast-moble-content">
        <div
          class="home-fast-moble-content-item"
          v-for="(item, ind) in fastList"
          :key="ind"
        >
          <div class="fast-item-title">
            <img v-lazy="item.url" />
            <span>{{ item.title }}</span>
          </div>
          <div class="fast-item-txt">
            {{ item.txt }}
          </div>
        </div>
      </div>
    </div>
    <div class="home-download">
      <div class="home-download-title">Download Litely Now!</div>
      <div class="home-download-describe">
        You are not alone! Start your fasting life together with other Litely
        users today!
      </div>
      <div class="home-download-btn">
        <a :href="googleDownloadLink" target="_blank"
          ><img
            src="https://media.litelyapp.com/web/google_download.webp"
            alt="googlePay"
        /></a>
        <a :href="appStoreDownloadLink" target="_blank"
          ><img
            src="https://media.litelyapp.com/web/ios_download.webp"
            alt="ios"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
//<iframe src="https://litelyapp.com/blog/" class="iframe"></iframe>
export default {
  data() {
    return {
      isPhoneWidth: false,
      is_submit_suc: false,
      isLoading: false,
      googleDownloadLink:
        "https://play.google.com/store/apps/details?id=lose.weight.together",
      appStoreDownloadLink: "https://apps.apple.com/us/app/id1619753790",
      tipList: [
        "An innovative fasting tracker",
        "A supportive fasting community",
        "Get motivated in peer support groups",
        "Lose weight & Live a healthy lifestyle",
      ],
      whoList: [
        {
          url: "https://media.litelyapp.com/web/who1.webp",
          txt: "Our fasting tracker is different from other fasting trackers. It has a fresh design and smart functions. It will guide you to fast every day.",
          title: "Unique Fasting Tracker",
        },
        {
          url: "https://media.litelyapp.com/web/who2.webp",
          txt: "Studies have shown that people who participated in a weight loss program with a group of friends lost more weight than those who were trying to do the same weight loss program alone. ",
          title: "Supportive Community",
        },
        {
          url: "https://media.litelyapp.com/web/who3.webp",
          txt: "The AI personal coach assists you to fast based on your own lifestyle. It will provide you with a customized fasting plan. Also, it provides you with a complete guide for intermittent fasting.",
          title: "Personal Fasting Coach ",
        },
        {
          url: "https://media.litelyapp.com/web/who4.webp",
          txt: "We've designed many feature-rich tools to assist you in managing your fasting. The water tracker helps you to drink enough water and stay hydrated!",
          title: "Powerful & Feature-rich Tools",
        },
      ],
      fastList: [
        {
          url: "https://media.litelyapp.com/web/fast1.webp",
          title: "Lose weight & control weight",
          txt: "IF could be the easiest way to lose weight. You don't need to restrict what you eat. Just simply improve your eating and fasting time to lose weight.",
        },
        {
          url: "https://media.litelyapp.com/web/fast2.webp",
          title: "Increase longevity",
          txt: "Fasting can remove some waste materials in your body. It triggers the growth hormone in your body, which helps you stay young and increase longevity.",
        },
        {
          url: "https://media.litelyapp.com/web/fast3.webp",
          title: "Reduce inflammation",
          txt: "Fasting can help you reduce inflammation. Inflammation is a root cause of many diseases.",
        },
        {
          url: "https://media.litelyapp.com/web/fast4.webp",
          title: "Prevent cancer",
          txt: "In some studies, intermittent fasting has been proved to help prevent cancer. Also, some research has shown that intermittent fasting helps reduce side effects of chemotherapy.",
        },
        {
          url: "https://media.litelyapp.com/web/fast5.webp",
          title: "Reduce insulin resistance",
          txt: "Studies have shown that intermittent fasting is an effective non-medicinal treatment way for type 2 diabetes, which is caused by insulin resistance.",
        },
        {
          url: "https://media.litelyapp.com/web/fast6.webp",
          title: "Improve overall health",
          txt: "Intermittent fasting helps people improve blood sugar level. It helps you strengthen your immune system and helps you improve your overall health.",
        },
      ],
      doctorOmg: "https://media.litelyapp.com/web/doctor.webp",
    };
  },
  created() {
    this.$store.commit("setIsApp", false);
  },
  mounted() {
    this.resetWidth();
    window.onresize = () => {
      this.resetWidth();
    };
  },
  components: {},
  computed: {},
  methods: {
    resetWidth() {
      var width = document.body.clientWidth;
      if (width <= 500) {
        this.isPhoneWidth = true;
      } else {
        this.isPhoneWidth = false;
      }
    },
    // isEmail(str) {
    //   //var reg = /^([a-zA-Z0-9_])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
    //   //^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$
    //   var reg =
    //     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    //   return reg.test(str);
    // },
    // submit_btn_clicked() {
    //   var input_value = this.$refs.input_ref.value;
    //   if (input_value === undefined || input_value.length === 0) return;

    //   var isemail = this.isEmail(input_value);
    //   if (isemail === false) {
    //     alert("please enter the correct email address..");
    //     return;
    //   }
    //   this.isLoading = true;
    //   document.scrollingElement.scrollTop = 0; //让页面滚动到最顶部
    //   this.$axios({
    //     method: "post",
    //     url: "https://www.litelyapp.com/wapi/contact", // 接口地址
    //     data: {
    //       email: input_value, // 传接口参数
    //     },
    //   })
    //     .then((response) => {
    //       console.log(response, "success"); // 成功的返回

    //       this.is_submit_suc = true;
    //       this.isLoading = false;
    //     })
    //     .catch((error) => {
    //       this.isLoading = false;
    //       console.log(error, "error");
    //     }); // 失败的返回
    // },
  },
};
</script>

<style lang="less" scoped>
@import "@/css/less_custom.less";

.top-bgi1 {
  mask: url(../assets/svg/top_bgi.svg) no-repeat bottom;
  -webkit-mask: url(../assets/svg/top_bgi.svg) no-repeat bottom; // -webkit-mask-size: cover;
  // mask-size: cover;
  background: linear-gradient(to right, #fff0ed, #ffc9c0);
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 779px;
  z-index: -1;
}

.container {
  // background-color: rgb(92, 71, 112);
  height: 100%;
  margin-top: 0 !important;
  padding-top: 40px;
}

.home-top {
  display: flex;
  width: 84%;
  padding: 0 8%;
  height: 779px;
  justify-content: space-between;

  .home-top-banner {
    img {
      max-width: 570px;
    }
  }

  .home-top-descript {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-right: 53px; // min-width: 400px;
    margin-bottom: 100px;

    .descript-title {
      font-size: 36px;
      color: #333;
      font-weight: bold;
      text-align: left;
    }

    .descript-tip {
      color: #666;
      font-size: 20px;
      margin-top: 5px;
    }

    .descript-list {
      margin-top: 24px;

      .descript-item {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #666;
        margin-bottom: 5px;

        img {
          width: 16px;
          height: 10px;
          margin-right: 12px;
        }
      }
    }

    .descript-download {
      margin-top: 55px;
      max-width: 380px;
      display: flex;

      .google-download {
        margin-right: 28px;
      }

      img {
        width: 100%;
      }
    }
  }
}

.home-describe {
  width: 84%;
  padding: 74px 8px 88px;
  margin: 0 auto;

  .home-describe-txt {
    margin-bottom: 48px;
  }

  .home-describe-imgs {
    display: flex;
    justify-content: space-between;
    height: 58px;

    img {
      height: 100%;
    }
  }

  @media screen and (max-width: @MiddleMaxWidth) {
    .home-describe-imgs {
      height: 30px;
    }
  }
}

.home-who {
  width: 84%;
  margin: 0 auto;
  padding: 100px 8%;
  background-image: linear-gradient(to right, #e9f9fe, #b9e8fe);

  .home-who-title {
    font-size: 34px;
    color: #333;
    font-weight: bold;
  }

  .home-who-describe {
    font-size: 18px;
    color: #333333;
    max-width: 800px;
    width: 80%;
    margin: 20px auto 80px;
  }

  .home-who-list {
    display: flex;
    justify-content: space-between;

    .home-who-item {
      width: 20%;
      text-align: left;

      img {
        width: 100%;
        border-radius: 6px;
      }

      .home-who-item-title {
        font-size: 20px;
        color: #333;
        margin: 33px 0 14px;
        font-weight: bold;
      }

      .home-who-item-txt {
        font-size: 14px;
        color: #666666;
      }
    }
  }
}

.home-fast {
  padding: 92px 8% 123px;
  width: 84%;
  margin: 0 auto;
  background-color: #fff;

  .home-fast-title {
    font-weight: bold;
    font-family: Helvetica-Bold, Helvetica;
    font-size: 34px;
  }

  .home-fast-describe {
    color: #666;
    font-family: Helvetica;
    font-size: 18px;
    max-width: 676px;
    margin: 20px auto 81px;
  }

  .home-fast-content {
    display: flex;
    align-items: center;

    .home-fast-content-banner {
      margin-right: 64px;
      flex: 3;

      img {
        width: 100%;
      }
    }

    .home-fast-content-list {
      flex: 5;
      display: flex;
      flex-wrap: wrap;

      .home-fast-content-item {
        width: calc(50% - 22px);
        margin-right: 44px;
        margin-top: 48px;

        .fast-item-title {
          display: flex;
          align-items: center;

          img {
            width: 46px;
            height: 46px;
            margin-right: 12px;
          }

          span {
            color: #333;
            font-weight: bold;
            font-size: 20px;
            text-align: left;
          }
        }

        .fast-item-txt {
          margin-top: 16px;
          font-size: 14px;
          color: #666;
          font-family: Helvetica;
          text-align: left;
        }
      }

      .home-fast-content-item:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

.home-download {
  padding: 97px 8%;
  margin: 0 auto;
  width: 84%;
  background-image: linear-gradient(to right, #fefadd, #ffe9b6);

  .home-download-title {
    font-size: 34px;
    font-family: Helvetica-Bold, Helvetica;
    color: #333333;
    font-weight: bold;
  }

  .home-download-describe {
    margin: 15px auto 21px;
  }

  .home-download-btn {
    display: flex;
    justify-content: center;

    img {
      width: 175px;
      height: 52px;
      cursor: pointer;
    }

    img:nth-child(1) {
      margin-right: 27px;
    }
  }
}

.top-bgi {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 779px;
  object-fit: cover;
  z-index: -1;
}

.submit_email {
  .top {
    display: flex;
    justify-content:flex-start;
    align-items: center;
    flex-direction: column;

    .logo {
      margin-top: 100px;
      margin-bottom: 10px;
      width: 80px;
      height: 80px; // background-color: chartreuse;
    }

    .title {
      color: rgba(253, 124, 97, 1);
      font-size: 24px;
      font-weight: 500;
    }

    @media screen and (max-width: @MobileMaxWidth) {
      .logo {
        margin-top: 50px;
        width: 60px;
        height: 60px;
      }

      .title {
        font-size: 20px;
      }
    }

    .main {
      margin-top: 35px;
      margin-bottom: 20px;
      text-align: center;
      font-size: 36px;
      color: rgba(23, 24, 27, 1);
      font-weight: 700;
      width: 60%;
    }

    .subtext {
      margin-bottom: 40px;
      text-align: center;
      font-size: 18px;
      color: rgba(23, 24, 27, 1);
      font-weight: 500;
      width: 50%;
    }

    @media screen and (max-width: @MobileMaxWidth) {
      .main {
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 24px;
        width: 90%;
      }

      .subtext {
        font-size: 15px;
        width: 80%;
      }
    }
  }

  .input_container {
    // background-color: chartreuse;
    position: relative;

    .content {
      // background-color: cornflowerblue;
      position: absolute;
      width: 100%;
      height: 100%; // left: 300px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      z-index: 1;

      .desc {
        margin-top: 70px;
        margin-bottom: 35px;
        text-align: center;
        font-size: 24px;
        color: rgba(255, 255, 255, 1);
        font-weight: 700;
        width: 40%;
      }

      .email_container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 22px;

        .input {
          width: 25%;
          height: 61px;
          background-color: rgba(206, 187, 187, 0.534);
          margin-right: 7px;
          border-top-left-radius: 60px;
          border-top-right-radius: 4px;
          border-bottom-left-radius: 60px;
          border-bottom-right-radius: 4px;
          border: none;
          outline: none;
          padding-left: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-weight: 600;
          font-size: 16px;
        }

        input::-webkit-input-placeholder {
          /* placeholder颜色  */
          color: white;
          /* placeholder字体大小  */
          font-size: 14px;
          /* placeholder位置  */
          text-align: center;
        }

        .submit_btn {
          width: 12%;
          height: 61px;
          background-color: rgb(216, 216, 110);
          border-top-left-radius: 4px;
          border-top-right-radius: 60px;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          font-size: 13px;
          cursor: pointer;
        }
      }

      .image {
        width: 60%;
        aspect-ratio: 2.7; // background-color: darkgray;
      }

      @media screen and (max-width: @MobileMaxWidth) {
        .desc {
          margin-top: 30px;
          margin-bottom: 25px;
          text-align: center;
          font-size: 20px;
          width: 80%;
        }

        .email_container .input {
          width: 60%;
          height: 51px;
          font-size: 16px;
        }

        .email_container .submit_btn {
          width: 30%;
          height: 51px;
          font-size: 12px;
        }

        .image {
          width: 100%; // aspect-ratio: 2.7;
          // background-color: darkgray;
        }
      }
    }

    .testsvgBg {
      -webkit-mask-size: cover;
      mask-size: cover;
      width: 100%;
      height: 615px;
      background: linear-gradient(to right, #ffa164, #ff646b);
      -webkit-mask: url(../assets/svg/test.svg) no-repeat bottom;
      mask: url(../assets/svg/test.svg) no-repeat bottom;
      transform: rotateX(180deg);
    }

    @media screen and (max-width: @MobileMaxWidth) {
      .testsvgBg {
        height: 315px;
      }
    }
  }
}

@media screen and (max-width: @MiddleMaxWidth) {
  .home-top {
    .home-top-descript {
      flex: 1;
    }

    .home-top-banner {
      flex: 1;
      margin-bottom: 100px;
      display: flex;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: @MobileMaxWidth) {
  .home-top {
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    height: auto;
    margin-top: 10px;

    .home-top-descript {
      width: 90%;
      margin-bottom: 33px;
      margin: 0 auto 33px;

      .descript-title {
        font-size: 18px;
      }

      .descript-tip {
        margin: 15px auto 25px;
        font-size: 12px;
        font-weight: 600;
      }

      .descript-list {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .home-top-banner {
      margin: 0 auto;
      position: relative;
      width: 100%;
      justify-content: center;

      img {
        width: 217px;
        text-align: center;
      }

      .fixed-img {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .home-describe {
    padding: 44px 8px 32px;
    height: auto;

    .home-describe-txt {
      font-size: 12px;
      margin-bottom: 22px;
    }

    .home-describe-imgs {
      height: auto;
    }
  }

  .home-describe-rows {
    .home-describe-rows-imgs {
      display: flex;
      height: 33px;
      margin-bottom: 20px;
      justify-content: space-between;

      img {
        height: 100%;
      }
    }

    .home-describe-rows-imgs:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .home-who {
    box-sizing: border-box;
    width: 100%;
    padding: 36px 5%;
    background: linear-gradient(
      131deg,
      #e9f9fe 0%,
      #ddf5ff 29%,
      #d5f2ff 51%,
      #c6ecfe 78%,
      #b9e8fe 100%,
      #b9e8fe 100%
    );

    .home-who-describe {
      width: 100%;
      margin: 12px 0 20px;
    }
  }

  .home-who-model-list {
    .home-who-model {
      padding: 41px 5% 28px;
    }

    .home-who-model3 {
      background: linear-gradient(135deg, #fff2ef 0%, #ffccc3 100%);
    }
  }

  .home-who-model {
    padding: 41px 0 28px;

    img {
      width: 239px;
    }

    .home-who-model-title {
      color: #333333;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      font-size: 16px;
      margin: 29px auto 15px;
    }

    .home-who-model-txt {
      font-size: 12px;
    }
  }

  .home-fast-moble {
    width: 100%;

    .home-fast-moble-top {
      padding: 36px 5% 0;
      background: linear-gradient(135deg, #e9f9fe 0%, #b9e8fe 100%);

      .home-fast-moble-top-title {
        font-size: 18px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #333333;
      }

      .home-fast-moble-top-describe {
        font-family: Helvetica;
        color: #666666;
        font-size: 12px;
        margin: 12px auto 56px;
      }

      .home-fast-moble-top-banner {
        width: 100%;

        img {
          width: 100%;
          display: block;
        }
      }
    }

    .home-fast-moble-content {
      display: flex;
      padding: 36px 5% 0;
      flex-wrap: wrap;

      .home-fast-moble-content-item {
        margin-right: 15px;
        width: calc((100% - 15px) / 2);
        margin-bottom: 32px;

        .fast-item-title {
          display: flex;
          align-items: center;

          img {
            width: 22px;
            height: 22px;
            margin-right: 5px;
          }

          span {
            font-size: 10px;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #333333;
            text-align: left;
          }
        }

        .fast-item-txt {
          margin-top: 11px;
          font-size: 10px;
          font-family: Helvetica;
          color: #666666;
          text-align: left;
        }
      }

      .home-fast-moble-content-item:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  .home-download {
    padding: 26px 8% 32px;

    .home-download-title {
      font-size: 18px;
    }

    .home-download-describe {
      margin: 12px auto 20px;
      font-size: 12px;
    }

    .home-download-btn {
      display: flex;
      justify-content: center;

      img {
        width: 91px;
        height: 27px;
        cursor: pointer;
      }

      img:nth-child(1) {
        margin-right: 15px;
      }
    }
  }
}
</style>
